import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'

import { useAppProviderContext } from '../../core/application/contexts'
import { NotificationContext } from '../../core/application/contexts/NotificationContext'

const NotificationManager: React.FC = ({ children }) => {
  const { swappyProvider, isAuth } = useAppProviderContext()
  const [notificationCount, setNotificationCount] = useState<number>(0)
  const { data: notificationList, isLoading } = useQuery(
    'notificationList',
    async () => swappyProvider?.getNotificationList(),
    {
      enabled: swappyProvider && isAuth
    }
  )

  useEffect(() => {
    if (notificationList && !isLoading) {
      setNotificationCount(notificationList.filter(it => it.isNew).length)
    }
  }, [setNotificationCount, notificationList, isLoading])


  return (
    <NotificationContext.Provider
      value={{
        notificationCount,
        setNotificationCount
      }}>
      {children}
    </NotificationContext.Provider>
  )

}

export default NotificationManager
